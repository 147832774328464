<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Einspluseins - Kompetenzliste</v-card-title
        >
      </v-card>
    </div>

    <br />
    <br />

    <div v-for="(n, index) in tables.length" :key="n">
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="3">
                  {{ tables[index].title }}
                  <div v-if="index === 0" style="text-align: center">
                    (Hinweis: Alle Angaben auf dieser Seite werden automatisch
                    gespeichert)
                  </div>
                </td>
              </tr>
              <tr>
                <td><strong>Kompetenz: Ich kann...</strong></td>
                <td width="140px" class="no-wrap" style="text-align: center">
                  <strong>unsicher - sicher</strong>
                </td>
                <td width="35%">
                  <strong>Meine Kommentare</strong>
                </td>
              </tr>
              <tr v-for="(m, index1) in tables[index].checks.length" :key="m">
                <td>
                  {{ tables[index].checks[index1].description }}
                </td>
                <td>
                  <v-radio-group
                    dense
                    row
                    v-model="tables[index].checks[index1].level"
                    @change="saveState"
                  >
                    <v-radio
                      v-for="o in 4"
                      :key="o"
                      :value="o"
                      style="margin-right: -7px; margin-left: 0px;  justify-content: center;"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-textarea
                    full-width
                    auto-grow
                    rows="0"
                    label="Kommentar"
                    @input="saveState"
                    v-model="tables[index].checks[index1].comment"
                    data-matomo-unmask
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <br />
      <br />
    </div>

    <v-btn
      class="white--text"
      x-large
      color="#477B7E"
      to="/home"
      style="float: right"
    >
      <v-icon>mdi-arrow-left</v-icon>
      Zurück zur Themenübersicht
    </v-btn>

    <AppBottomNavEPE back="/einspluseins/diagnose-und-foerderung" />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
export default {
  components: {
    AppBottomNavEPE,
  },
  mounted: function() {
    this.restoreState();
  },
  data() {
    return {
      tables: [
        {
          title: "Einspluseins: Grundvorstellungen der Addition",
          checks: [
            {
              description:
                "… die drei auf FALEDIA thematisierten Grundvorstellungen zur Addition benennen und erklären, welche Bedeutung hinter diesen steckt.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… unterschiedliche Aufgaben den entsprechenden Grundvorstellungen der Addition zuordnen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen, was Lernende in Bezug auf die hier thematisierten Grundvorstellungen der Addition wissen sollen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Bedeutung das Wissen um die Grundvorstellungen der Addition für das weitere mathematische Verständnis hat.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Einspluseins: Aufgabenbeziehungen nutzen",
          checks: [
            {
              description:
                "… die Kernaufgaben des Einspluseins benennen und erklären, mit welchen Strategien die restlichen Aufgaben des Einspluseins abgeleitet werden können.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, inwiefern Rechengesetze bei der Herstellung von Aufgabenbeziehungen eine besondere Rolle spielen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Kinderlösungen interpretieren, genutzte Rechengesetze benennen und das (Weiter-)Denken der Kinder antizipieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… an Beispielen begründen, wann und warum die Nutzung eines Rechengesetzes sinnvoll ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, warum die Auseinandersetzung mit der Einspluseinstafel der allgemeinen Fähigkeit zum beziehungsreichen Rechnen dient.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Bedeutung das Wissen um Aufgabenbeziehungen und Beziehungen zwischen Rechengesetzen für das sichere Erlernen des Einspluseins hat.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Einspluseins: Darstellungen vernetzen",
          checks: [
            {
              description:
                "…die vier zentralen Darstellungsformen benennen und Beispiele für diese angeben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, warum Anschauungs- und Erarbeitungsmittel nicht nur für leistungsschwache Kinder als Verständnishilfe geeignet ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, wann Darstellungen zueinanderpassen und wann nicht.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… wann und warum eine Darstellung den Vorstellungsaufbau zum Einspluseins begünstigt und wann nicht.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Bedeutung das Wissen um die Darstellungsformen und deren Vernetzung für den Erwerb des Einspluseins hat.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Einspluseins: Diagnose und Förderung",
          checks: [
            {
              description:
                "… die drei zentralen Hintergrundfacetten des Einspluseins nennen und erklären, warum dieses Hintergrundwissen für die Diagnose und Förderung von Lernenden wichtig ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Kinderdokumente hinsichtlich genutzter Grundvorstellungen der Addition und Rechengesetze interpretieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… bereits vorhandene Kompetenzen im Bereich des Einspluseins anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Fehler im Bereich des Einspluseins anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Ursachen für Fehler im Bereich des Einspluseins anhand von Kinderdokumenten begründet ableiten.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… passgenaue Diagnoseaufgaben auswählen und diese Auswahl auch begründen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Relevanz die Vernetzung von Aufgaben in der Unterrichtsgestaltung darstellt.",
              level: 0,
              comment: "",
            },
            {
              description: "... diagnosegeleitet Förderaufgaben auswählen.",
              level: 0,
              comment: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    restoreState: function() {
      const storedId = "CheckEPE";

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storedId]
        : false;

      if (restoredState) {
        this.tables = restoredState.data.tables;
      }
      if (!this.$store.hasModule(["nested", storedId])) {
        this.$store.registerModule(["nested", storedId], {
          namespaced: true,
          state: {
            data: {},
          },
          mutations: {
            data(state, payload) {
              state.data = {
                tables: payload.tables,
              };
            },
          },
        });
        this.saveStateLocal();
      }
    },

    saveStateLocal: function() {
      const storedId = "CheckEPE";
      this.$store.commit("nested/" + storedId + "/data", { ...this._data });
    },

    saveState: function() {
      this.saveStateLocal();
      //send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: { Authorization: token },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            //if there is an error, we do not save the state
          });
      } catch (err) {
        //if there is an error, we do not save the state
      }
    },
  },
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
#app .tdButton {
  position: absolute;
  right: -10px;
  bottom: -5px;
}
</style>
